<template>
  <el-main class="faq-container">
    <section class="inner-container">
      <div>
        <!-- 第一個不加 CSS -->
        <div>
          {{ $t('FAQ_Provider.Eligibility') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_Eligibility.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Eligibility.Q1_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <div slot="title" class="faq-title">
              Q2.{{ $t('FAQ_Provider.About_Eligibility.Q2') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Eligibility.Q2_Answer') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div>
        <div class="section-title">
          {{ $t('FAQ_Provider.Compliance') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_Compliance.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Compliance.Q1_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <div slot="title" class="faq-title">
              Q2.{{ $t('FAQ_Provider.About_Compliance.Q2') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Compliance.Q2_Answer1') }}
              <DownloadFileButton
                :button-type="'button'"
                :aria-label="$t('general.Download')+$t('navbar.Compliance_handbook')+$t('File.File')"
                :file-para="'person_data_consent'"
                :file-name="'個資同意書(範本).docx'"
                :button-content="$t('FAQ_Provider.About_Compliance.Q2_Answer2')"
              />
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <div slot="title" class="faq-title">
              Q3.{{ $t('FAQ_Provider.About_Compliance.Q3') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Compliance.Q3_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <div slot="title" class="faq-title">
              Q4.{{ $t('FAQ_Provider.About_Compliance.Q4') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Compliance.Q4_Answer') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div>
        <div class="section-title">
          {{ $t('FAQ_Provider.Field_Specification_and_Data_Quality') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q1_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <div slot="title" class="faq-title">
              Q2.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q2') }}
            </div>
            <div class="faq-content">
              <template v-if="language === 'jp'">
                <router-link to="/DataSpec">
                  {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q2_Answer1') }}
                </router-link>
                {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q2_Answer2') }}
              </template>
              <template v-else>
                {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q2_Answer1') }}
                <router-link to="/DataSpec">
                  {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q2_Answer2') }}
                </router-link>
              </template>
            </div>
          </el-collapse-item>
          <el-collapse-item name="3">
            <div slot="title" class="faq-title">
              Q3.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_Answer1') }}
              <a 
                href="mailto:service@data-sports.tw"
                :aria-label="$t('Aria.Mailto')"
              >
                {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_mailto') }}
              </a>
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_Answer2') }}
              {{ $t('general.Download') }} :
              <DownloadFileButton
                :button-type="'button'"
                :class-name="'download-button'"
                :aria-label="$t(`general.Download`)+$t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_File_name')"
                :file-para="'apply_field_spec'"
                :file-name="`${$t(`FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_File_name`)}.docx`"
                :button-content="$t(`FAQ_Provider.About_Field_Specification_and_Data_Quality.Q3_File_name`)"
              />
            </div>
          </el-collapse-item>
          <el-collapse-item name="4">
            <div slot="title" class="faq-title">
              Q4.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q4') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q4_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="5">
            <div slot="title" class="faq-title">
              Q5.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q5') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q5_Answer1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q5_Answer2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="6">
            <div slot="title" class="faq-title">
              Q6.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q6') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q6_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="7">
            <div slot="title" class="faq-title">
              Q7.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q7') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q7_Answer1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q5_Answer2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="8">
            <div slot="title" class="faq-title">
              Q8.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q8') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q8_Answer') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="9">
            <div slot="title" class="faq-title">
              Q9.{{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q9') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Field_Specification_and_Data_Quality.Q9_Answer') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div>
        <div class="section-title">
          {{ $t('FAQ_Provider.API_Concatenation') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_API_Concatenation.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_API_Concatenation.Q1_Answer1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_API_Concatenation.Q1_Answer2') }}
            </div>
          </el-collapse-item>
          <el-collapse-item name="2">
            <div slot="title" class="faq-title">
              Q2.{{ $t('FAQ_Provider.About_API_Concatenation.Q2') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_API_Concatenation.Q2_Answer') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div>
        <div class="section-title">
          {{ $t('FAQ_Provider.Data_Security') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_Data_Security.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Data_Security.Q1_Answer1') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div>
        <div class="section-title">
          {{ $t('FAQ_Provider.Management') }}
        </div>
        <el-collapse>
          <el-collapse-item name="1">
            <div slot="title" class="faq-title">
              Q1.{{ $t('FAQ_Provider.About_Management.Q1') }}
            </div>
            <div class="faq-content">
              {{ $t('FAQ_Provider.About_Management.Q1_Answer1') }}
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </section>

  </el-main>
</template>

<script>
import { mapGetters } from 'vuex'
import DownloadFileButton from '@/components/Button/DownloadFileButton.vue'

export default {
  name: 'FAQProvider',
  components: {
    DownloadFileButton
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({ language: 'language' })
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.faq-container {
  color: $text_dark;
  font-size: 1rem;
  .inner-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 1.25rem 0;
    .section-title{
      padding-top: 20px;
    }
  }
 
  .el-collapse{
    padding: 1.25rem 0;
    .el-collapse-item{
      .faq-title{
        padding-left: 1rem;
        font-size: 1.25rem;
        line-height: 2rem;
      }
      .faq-content{
        padding: 0 2rem;
        font-size: 1rem;

        a{
          color: $primary
        }
        a:hover{
          font-weight: bold;
        }
      }

    }
  }

  ::v-deep .el-collapse-item__header {
     height: auto;
     padding: 0.5rem 0;
   }
}
</style>
